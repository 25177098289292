import { useQuery, gql } from "@apollo/client";
import { Outlet } from "react-router-dom";
import { InView } from "react-intersection-observer";
import ReactMarkdown from "react-markdown";
import { url } from "../App";
const HEADER = gql`
    query getHeader {
        darrenperry {
            data {
                attributes {
                    header {
                        title
                        sub
                        image {
                            data {
                                attributes {
                                    url
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
export default function SharedLayout() {
    const { loading, error, data } = useQuery(HEADER);
    if (loading)
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    if (error) return <></>;
    var header = data.darrenperry.data.attributes.header;
    return (
        <>
            <InView
                as="div"
                onChange={(inView, entry) => headerInView(entry, inView)}
                style={{
                    backgroundImage: `url(${
                        url + header.image.data.attributes.url
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
                className="header row no-margin p-0 fade-in">
                <div className="v-center col-lg-8 offset-lg-2">
                    <h1>{header.title}</h1>
                    <ReactMarkdown>{header.sub}</ReactMarkdown>
                </div>
                <div
                    onClick={() => window.scrollTo(0, 0)}
                    className="to-top fade-out">
                    <i className="fa fa-angle-up"></i>
                </div>
            </InView>
            <Outlet />
        </>
    );
}

function headerInView(entry, inView) {
    if (!inView) {
        entry.target.children[1].classList.remove("fade-out");
        entry.target.children[1].classList.add("fade-in");
    } else {
        entry.target.children[1].classList.remove("fade-in");
        entry.target.children[1].classList.add("fade-out");
    }
}
