import { useQuery, gql } from "@apollo/client";
import ReactMarkdown from "react-markdown";
import { InView } from "react-intersection-observer";
import { url } from "../App";
const HOME = gql`
    query getHome {
        darrenperry {
            data {
                attributes {
                    header {
                        title
                        sub
                        image {
                            data {
                                attributes {
                                    url
                                    name
                                }
                            }
                        }
                    }
                    highlight {
                        title
                        image {
                            data {
                                attributes {
                                    url
                                    name
                                }
                            }
                        }
                    }
                    info
                    email
                    socials {
                        link
                        icon
                    }
                    timeline {
                        title
                        date
                        description
                    }
                }
            }
        }
    }
`;

export default function Home() {
    const { loading, error, data } = useQuery(HOME);
    if (loading)
        return (
            <div className="loader-container">
                <div className="loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        );
    if (error)
        return (
            <div className="container">
                <div className="row">
                    <div className="col text-center mt-5">
                        <h2>Oops, it looks like something went wrong</h2>
                        <p className="m-auto">
                            I will try to get this fixed soon. In the mean time
                            you can contact me here..
                        </p>
                        <a
                            className="m-auto"
                            href={"mailto:darrencperry@gmail.com"}
                            target="_blank"
                            rel="noreferrer">
                            darrencperry@gmail.com
                        </a>
                    </div>
                </div>
            </div>
        );
    var highlight = data.darrenperry.data.attributes.highlight;
    var info = data.darrenperry.data.attributes.info;
    var email = data.darrenperry.data.attributes.email;
    var socials = data.darrenperry.data.attributes.socials;
    var timeline = data.darrenperry.data.attributes.timeline;
    return (
        <div className="fade-in">
            {/* Highlight */}
            <div className="highlight row no-margin row-cols-1 row-cols-lg-4">
                {highlight.map((h, index) => {
                    return (
                        <div
                            id={"highlight-" + index}
                            key={h.title}
                            className="col">
                            <div className="highlight-card">
                                <img
                                    className="highlight-img"
                                    src={url + h.image.data.attributes.url}
                                    alt={h.image.data.attributes.name}
                                />
                                <div className="text-center highlight-card-inner">
                                    <h4 className="v-center w-100">
                                        {h.title}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="info text-center row no-margin">
                <ReactMarkdown>{info}</ReactMarkdown>
            </div>
            {/* Contact */}
            <div className="contact text-center row no-margin">
                <h3>Get In Touch</h3>
                <a
                    className="m-auto"
                    href={"mailto:" + email}
                    target="_blank"
                    rel="noreferrer">
                    {email}
                </a>
                <div className="socials">
                    {socials.map((s) => {
                        return (
                            <a
                                key={s.icon}
                                className={s.icon + " social-link"}
                                target="_blank"
                                rel="noreferrer"
                                href={s.link}>
                                <i className={"fa-brands fa-" + s.icon}></i>
                            </a>
                        );
                    })}
                </div>
            </div>
            {/* timeline */}
            <div className="timeline row no-margin">
                <h2 className="text-center">Selected Projects</h2>
                <div className="timeline-block container">
                    {/*timeline content */}
                    {timeline.map((t, index) => {
                        if (index % 2) {
                            return (
                                <InView
                                    key={t.title}
                                    as="div"
                                    onChange={(inView, entry) =>
                                        timelineInView(entry, inView)
                                    }
                                    className="timeline-container fade-out">
                                    <div className="timeline-img"></div>
                                    <div className="timeline-wrapper row">
                                        <div className="timeline-date d-none d-lg-block col-12 col-lg-5">
                                            <p className="text-end">{t.date}</p>
                                        </div>
                                        <div className="timeline-content col-12 col-lg-5 offset-lg-2">
                                            <h3>{t.title}</h3>
                                            <ReactMarkdown>
                                                {t.description}
                                            </ReactMarkdown>
                                        </div>
                                        <div className="timeline-date d-block d-lg-none col-12 col-lg-5">
                                            <p>{t.date}</p>
                                        </div>
                                    </div>
                                </InView>
                            );
                        } else
                            return (
                                <InView
                                    key={t.title}
                                    as="div"
                                    onChange={(inView, entry) =>
                                        timelineInView(entry, inView)
                                    }
                                    className="timeline-container fade-out">
                                    <div className="timeline-img"></div>
                                    <div className="timeline-wrapper row">
                                        <div className="timeline-content col-12 col-lg-5">
                                            <h3>{t.title}</h3>
                                            <ReactMarkdown>
                                                {t.description}
                                            </ReactMarkdown>
                                        </div>
                                        <div className="timeline-date col-12 col-lg-5 offset-lg-2">
                                            <p>{t.date}</p>
                                        </div>
                                    </div>
                                </InView>
                            );
                    })}
                </div>
            </div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-12 offset-lg-1 col-lg-6">
                            <p>© Darren Perry 2019</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

function timelineInView(entry, inView) {
    if (inView) {
        entry.target.classList.remove("fade-out");
        entry.target.classList.add("fade-in");
        for (let i = 0; i < entry.target.children.length; i++) {
            entry.target.children[i].classList.add("scale");
        }
    }
}
